import styled from '@emotion/styled'
import { mediaQueryStrings } from '@lib/media-queries'
import voucciTheme from '@lib/theme'
import { lighten } from 'polished'

export const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '30px',
  marginTop: '30px',
  width: '100%',
})

export const CategoriesWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'start',
  marginTop: '30px',
  paddingBottom: '30px',
  overflowX: 'scroll',
  overflowY: 'visible',
  width: '100%',

  a: {
    textDecoration: 'none',
  },
})

export const CategoryCard = styled.div(
  {
    display: 'flex',
    flexShrink: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '15px',
    width: '100%',
  },
  ({ theme = voucciTheme }) => ({
    backgroundColor: theme.colors.white,
    borderRadius: '15px',
    boxShadow: `0 4px 8px 0 ${lighten(0.25, theme.colors.textLight)}`,
    color: theme.colors.textLight,
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    transition:
      'color 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease-in-out',
    transform: 'translateY(4px)',

    '&:hover': {
      boxShadow: `0 8px 16px 0 ${lighten(0.25, theme.colors.textLight)}`,
      color: theme.colors.text,
      transform: 'translateY(0px)',
    },
  }),
)

export const CategoryCardWrapper = styled.div({
  [`${mediaQueryStrings.mobile}`]: {
    width: 'calc((100% / 2) - (15px * 1 / 2))',

    '&:not(:nth-of-type(2n+1))': {
      marginLeft: '15px',
    },
    '&:nth-of-type(n+3)': {
      marginTop: '15px',
    },
  },
  [`${mediaQueryStrings.tablet}`]: {
    width: 'calc((100% / 4) - (15px * 3 / 4))',

    '&:not(:nth-of-type(4n+1))': {
      marginLeft: '15px',
    },
    '&:nth-of-type(n+5)': {
      marginTop: '15px',
    },
  },
  [`${mediaQueryStrings.desktop}`]: {
    width: 'calc((100% / 6) - (15px * 5 / 6))',

    '&:not(:nth-of-type(6n+1))': {
      marginLeft: '15px',
    },
    '&:nth-of-type(n+7)': {
      marginTop: '15px',
    },
  },
})

export const CategoryImage = styled.img({
  filter:
    'brightness(0) saturate(100%) invert(71%) sepia(19%) saturate(7219%) hue-rotate(179deg) brightness(95%) contrast(87%)',
  maxHeight: '100%',
  maxWidth: '100%',
})

export const CategoryImageContainer = styled.div({
  borderRadius: '50%',
  bottom: 0,
  height: '100%',
  left: 0,
  padding: '25%',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
})

export const CategoryImageWrapper = styled.div(
  {
    borderRadius: '50%',
    paddingTop: '100%',
    position: 'relative',
    transition: 'opacity 0.25s ease-in-out',
    width: '100%',
  },
  ({ theme = voucciTheme }) => ({
    backgroundColor: theme.colors.secondary,
  }),
)

export const FeaturedWrapper = styled.div({
  marginTop: '30px',
})

export const Heading = styled.h2({
  textAlign: 'center',
})

interface MoreWrapperProps {
  expanded?: boolean
}

export const MoreWrapper = styled.div<MoreWrapperProps>(
  {
    marginTop: '30px',
    overflow: 'hidden',
    transition: 'height 0.25s ease-in-out',
  },
  ({ expanded = false }) => ({
    height: expanded ? 'auto' : 0,
  }),
)

export const SectionWrapper = styled.div({
  width: '100%',
})

export const SubHeading = styled.h3(
  {
    fontSize: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  ({ theme = voucciTheme }) => ({
    color: `${theme.colors.textLight}!important`,
  }),
)
