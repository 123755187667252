/// <reference types="@types/google.maps" />

import CategoriesComponent from '@components/core/categories'
import Module, { FlexAlign, FlexDirection } from '@components/core/module'
import { useVoucciState } from '@context/state'
import { geocodeCoordinates } from '@lib/api/v1/maps'
import { ModuleThemes } from '@lib/theme'
import { getLastKnownLocation, setLastKnownLocation } from '@lib/voucci/client'
import { Category } from '@models/categories'
import { FC, useEffect, useState } from 'react'
import type { Locale, Place, StoredLocation } from 'types'

interface HomeComponentProps {
  categories: Category[]
}

const HomeComponent: FC<HomeComponentProps> = ({ categories }) => {
  const voucciState = useVoucciState()

  const [locale, setLocale] = useState<Locale>(null)

  useEffect(() => {
    if (voucciState && voucciState.location) {
      const storedLocation = voucciState.location

      if (storedLocation) {
        setLocale(storedLocation.locale)
      }
    }
  }, [voucciState])

  return (
    <>
      <Module
        alignItems={FlexAlign.Center}
        colorScheme={ModuleThemes.Primary}
        flexDirection={FlexDirection.Column}
      >
        <h1>Verified reviews for authentic ethnic cuisine</h1>
        <p>
          Voucci cuts through the nonsense by verifying that reviews for
          restaurants are from verified experts of the type of cuisine being
          reviewed.
        </p>
      </Module>
      <Module colorScheme={ModuleThemes.Secondary}>
        <CategoriesComponent categories={categories} locale={locale} />
      </Module>
    </>
  )
}

export default HomeComponent
