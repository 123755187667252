import { InferGetServerSidePropsType, NextPage } from 'next'
import Head from 'next/head'
import { authGetServerSideProps } from '@lib/next'
import Page from '@components/page'
import HomeComponent from '@components/pages/home'

const HomePage: NextPage = ({
  ...rest
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <Page {...rest}>
      <Head>
        <title>Voucci - Home</title>
      </Head>
      <HomeComponent categories={rest.categories} />
    </Page>
  )
}

export const getServerSideProps = authGetServerSideProps

export default HomePage
