import { Category } from '@models/categories'
import { FC, useEffect, useState } from 'react'
import {
  ButtonWrapper,
  CategoriesWrapper,
  CategoryCard,
  CategoryCardWrapper,
  CategoryImage,
  CategoryImageContainer,
  CategoryImageWrapper,
  FeaturedWrapper,
  Heading,
  MoreWrapper,
  SectionWrapper,
  SubHeading,
} from './style'
import Link from 'next/link'
import { Locale } from 'types'
import { ButtonSize, PrimaryButton } from '../buttons'

interface CategoriesComponentProps {
  categories: Category[]
  locale: Locale
}

const CategoriesComponent: FC<CategoriesComponentProps> = ({
  categories: initialCategories,
  locale,
}) => {
  const [categories, setCategories] = useState<Category[]>([])
  const [featured, setFeatured] = useState<Category[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)
  const [moreCategoriesVisible, setMoreCategoriesVisible] =
    useState<boolean>(false)

  useEffect(() => {
    if (initialCategories && featured && !featured.length && !initialized) {
      setInitialized(true)

      const featuredCategories = initialCategories
        .filter((category) => category.featured)
        .sort((a, b) => a.name.localeCompare(b.name))

      setFeatured(featuredCategories)
    }

    if (initialCategories && categories && !categories.length && !initialized) {
      setInitialized(true)

      const categories = initialCategories
        .filter((category) => !category.featured)
        .sort((a, b) => a.name.localeCompare(b.name))

      setCategories(categories)
    }
  }, [categories, initialized, featured, initialCategories])

  return (
    <SectionWrapper>
      <Heading>
        Find food near {locale && <>{`${locale.city}, ${locale.state}`}</>}
      </Heading>

      <FeaturedWrapper>
        <SubHeading>Featured cuisines</SubHeading>
        <CategoriesWrapper>
          {featured.map((category) => (
            <CategoryCardWrapper key={category.id}>
              <Link href={`/categories/${category.slug}`}>
                <CategoryCard>
                  <CategoryImageWrapper>
                    <CategoryImageContainer>
                      <CategoryImage src={`/icons/icon-${category.icon}.svg`} />
                    </CategoryImageContainer>
                  </CategoryImageWrapper>
                  <p>{category.name}</p>
                </CategoryCard>
              </Link>
            </CategoryCardWrapper>
          ))}
        </CategoriesWrapper>
      </FeaturedWrapper>

      <ButtonWrapper>
        <PrimaryButton
          size={ButtonSize.Medium}
          onClick={() => setMoreCategoriesVisible(!moreCategoriesVisible)}
        >
          {moreCategoriesVisible ? `Show less` : `Show more`}
        </PrimaryButton>
      </ButtonWrapper>

      <MoreWrapper expanded={moreCategoriesVisible}>
        <SubHeading>More cuisines</SubHeading>
        <CategoriesWrapper>
          {categories.map((category) => (
            <CategoryCardWrapper key={category.id}>
              <Link href={`/categories/${category.slug}`}>
                <CategoryCard>
                  <CategoryImageWrapper>
                    <CategoryImageContainer>
                      <CategoryImage src={`/icons/icon-${category.icon}.svg`} />
                    </CategoryImageContainer>
                  </CategoryImageWrapper>
                  <p>{category.name}</p>
                </CategoryCard>
              </Link>
            </CategoryCardWrapper>
          ))}
        </CategoriesWrapper>
      </MoreWrapper>
    </SectionWrapper>
  )
}

export default CategoriesComponent
